const featured_projects = [
  // {
  //   "id": 0,
  //   "name": "Cactus",
  //   "link": "https://www.cactuscan.com/",
  //   "description": "🌵 A responsive multi-page website made with wordpress for ease of updating & blog functionality. Used jQuery for custom animations and extension integration.",
  //   "colour": "linear-gradient(to top, rgb(0, 122, 65),rgb(37, 207, 108))",
  //   "languages": [
  //     {
  //       "name": "Jquery",
  //       "color": "rgb(241, 224, 90)"
  //     },
  //     {
  //       "name": "Wordpress",
  //       "color": "rgb(100, 219, 255)"
  //     }
  //   ]
  // },
  // {
  //   "id": 1,
  //   "name": "Bartini",
  //   "link": "https://www.bartiniapp.com/",
  //   "description": "🍹 A cocktail-making web application. Users can input ingredients and browse cocktails that can be made. Responsible for UI/UX on mobile & web.",
  //   "colour": "linear-gradient(to bottom right, rgb(193, 56, 85),rgb(230, 162, 53))",
  //   "languages": [
  //     {
  //       "name": "JavaScript",
  //       "color": "rgb(241, 224, 90)"
  //     },
  //     {
  //       "name": "CSS",
  //       "color": "rgb(86, 61, 124)"
  //     },
  //     {
  //       "name": "UX/UI",
  //       "color": "white"
  //     }
  //   ]
  // }
];

export { featured_projects };
